import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./button.module.css";

const Button = ({ buttonName, buttonType, onClick, className, disabled, icon }) => {
  const buttonClass = classNames(styles.buttonBase, className, { [styles.buttonDisabled]: disabled });

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={buttonType || "button"} onClick={onClick} className={buttonClass} disabled={disabled}>
      {buttonName} {icon}
    </button>
  );
};

Button.propTypes = {
  buttonName: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
};

Button.defaultProps = {
  buttonType: "button",
  className: "",
  disabled: false,
  icon: null,
};

export default Button;
