import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./navbar.module.css";
import LanguageSelector from "../../features/language_selector/LanguageSelector";
import MobilMenu from "./MobilMenu";
import { selectAuth } from "../../features/auth/selectors";
import { logout } from "../../features/auth/authSlice";
import FeatureSelector from "../../features/feature_selector/FeatureSelector";

function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const { isAuth } = auth;

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <div className={styles.navbarBox}>
        <Link to="/" className={styles.logoBoxHome}>
          <img className={styles.logo} src="/Logo-home.webp" alt="synthetic-icon" />
          <span className={styles.textLogo}>synthetic</span>
        </Link>
        <nav className={styles.linkBoxHome}>
          <FeatureSelector />
          <button
            type="button"
            onClick={() => handleClick("pricing")}
            id="pricingButton"
            className={location.pathname === "/pricing" ? styles.activeLink : ""}
          >
            {t("pricing")}
          </button>
          <button
            type="button"
            onClick={() => handleClick("faq")}
            id="faqButton"
            className={location.pathname === "/faq" ? styles.activeLink : ""}
          >
            FAQ
          </button>
          <button
            type="button"
            onClick={() => handleClick("blog")}
            id="blogLink"
            className={location.pathname === "/blog" ? styles.activeLink : ""}
          >
            Blog
          </button>
          <button
            type="button"
            onClick={() => handleClick("partners")}
            id="partnerLink"
            className={location.pathname === "/partners" ? styles.activeLink : ""}
          >
            Partners
          </button>
          <LanguageSelector />
        </nav>
        <nav className={styles.signBox}>
          {isAuth ? (
            <>
              <button
                type="button"
                id="myAccountBtn"
                className={styles.logInHome}
                onClick={() => navigate("/account/mySynthetic")}
              >
                {t("myAccount")}
              </button>
              <button type="button" id="logoutBtn" className={styles.signUpHome} onClick={handleLogout}>
                {t("logOut")}
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className={styles.logInHome} id="loginButton">
                {t("logIn")}
              </Link>
              <Link to="/signUp" className={styles.signUpHome} id="signUpButton">
                {t("signUp")}
              </Link>
            </>
          )}
        </nav>
      </div>
      <MobilMenu />
    </>
  );
}

export default Navbar;
