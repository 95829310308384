// eslint-disable-next-line no-unused-vars
import React from "react";
import style from "./blockPartnerWithUs.module.css";

function BlockPartnerWithUs() {
  return (
    <div>
      <div className={style.block}>
        <div className={style.blocks}>
          <h2 className={style.mainHeader}>Why Partner With Us?</h2>
          <div className={style.block1}>
            <div className={style.imgContainer}>
              <img className={style.pic1} src="/coin_1.svg"/>
            </div>
            <h4 className={style.paragraphTitle1}>Enhance Your Clients' Campaigns: </h4>
            <div className={style.paragraph}>
              Our tool seamlessly integrates with landing pages and websites, driving better engagement and conversion
              rates.
            </div>
          </div>
          <div className={style.block2}>
            <div className={style.imgContainer}>
              <img className={style.pic2} src="/coin_2.svg"/>
            </div>
            <h4 className={style.paragraphTitle2}>Expand Your Service Portfolio:</h4>
            <div className={style.paragraph}>
              Offer a unique, high-value solution that sets you apart from competitors.
            </div>
          </div>
          <div className={style.block3}>
            <div className={style.imgContainer}>
              <img className={style.pic3} src="/coin_3.svg"/>
            </div>
            <h4 className={style.paragraphTitle3}>Boost Your Bottom Line:</h4>
            <div className={style.paragraph}>
              Enjoy attractive revenue sharing on all client subscriptions you bring in.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockPartnerWithUs;
