// eslint-disable-next-line no-unused-vars
import React from "react";
import styles from "./blockContactUs.module.css";
import style from "../how_it_works/blockHowItWorks.module.css";
import FormComponent from "../ContactUsFrom";

function BlockContactUs() {
  return (
    <div>
      <div className={styles.block}>
        <div className={style.blocks}>
          <div className={styles.globalTextContainer}>
            <h2 className={styles.headerContactUs}>Ready to take your agency to the next level? </h2>
            <div className={styles.otherTextContainer}>
              <h3 className={styles.otherTextContactUs}>
                Contact us today to learn more about our partnership opportunities.
              </h3>
            </div>
          </div>
          <FormComponent />
        </div>
      </div>
    </div>
  );
}

export default BlockContactUs;
