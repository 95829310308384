// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import styles from "./textSynthetic.module.css";

function TextSynthetic({ text, setText, handleClickBtn, setNewSynthetic, setTextChecked, newSynthetic }) {
  return (
    <div>
      <div className={`${styles.dropdownElement} ${styles.text}`}>
        <textarea id="textareaInput" value={text} maxLength={3875} onChange={(e) => setText(e.target.value)} />
        <div className={styles.requirementsInfo}>
          {text.length !== 0 && text.length <= 3875 && text !== "" ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <WarningAmberIcon style={{ color: "#EC4A0A" }} />
          )}
          {text?.length}/3875 characters
        </div>
        {text !== newSynthetic.text && text.length !== 0 ? (
          <button
            className={`${styles.btn}`}
            id="btnTextSave"
            type="button"
            onClick={() => {
              handleClickBtn("textSection", "btnTextSave");
              setNewSynthetic({ ...newSynthetic, text });
              setTextChecked(true);
            }}
          >
            Save
          </button>
        ) : (
          <button
            className={`${styles.btn} ${styles.noActiveBtn}`}
            id="btnTextSave"
            type="button"
            onClick={() => {
              handleClickBtn("textSection", "btnTextSave");
              setNewSynthetic({ ...newSynthetic, text });
              setTextChecked(true);
            }}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}

TextSynthetic.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  handleClickBtn: PropTypes.func.isRequired,
  setNewSynthetic: PropTypes.func.isRequired,
  setTextChecked: PropTypes.func.isRequired,
  newSynthetic: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

export default TextSynthetic;
