import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "./api";

const initialState = {
  avatars: [],
  sharedAvatars: [],
  languages: [],
  voices: [],
  synthetics: [],
  allPartners: [],
  synthetic: {},
  syntheticStyles: {},
  isLoadingCreateSyn: false,
  isLoadingUploadAvatar: false,
  isLoadingUpdateTask: false,
  isDeletingSyn: false,
  isLoadingFetchStyle: false,
  isLoadingUpdateStyle: false,
  errorExistMessage: "",
  error: false,
  showErrorWindow: false,
};

export const loadAllAvatar = createAsyncThunk("loadAllAvatar", async (_, { rejectWithValue }) => {
  try {
    return await api.getAllAvatarByUser();
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const loadAllSharedAvatars = createAsyncThunk("loadAllSharedAvatars", async (_, { rejectWithValue }) => {
  try {
    return await api.getAllSharedAvatars();
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const uploadSharedAvatar = createAsyncThunk("uploadSharedAvatar", async (avatarData, { rejectWithValue }) => {
  try {
    return await api.uploadSharedAvatar(avatarData);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const uploadAvatar = createAsyncThunk("uploadAvatar", async (avatarData, { rejectWithValue }) => {
  try {
    return await api.uploadAvatar(avatarData);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const uploadNewPartner = createAsyncThunk(
  "uploadNewPartner",
  async ({ newPartner, imageData }, { rejectWithValue }) => {
    try {
      return await api.uploadNewPartner(newPartner, imageData);
    } catch (error) {
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

export const loadAllLanguage = createAsyncThunk("loadAllLanguage", async (_, { rejectWithValue }) => {
  try {
    return await api.getAllLanguage();
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const createLanguage = createAsyncThunk("createLanguage", async (languageData, { rejectWithValue }) => {
  try {
    return await api.createLanguage(languageData);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const updateLanguage = createAsyncThunk(
  "languages/updateLanguage",
  async ({ languageId, languageData }, { rejectWithValue }) => {
    try {
      return await api.updateLanguage(languageId, languageData);
    } catch (error) {
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

export const getLanguageById = createAsyncThunk(
  "languages/getLanguageById",
  async (languageId, { rejectWithValue }) => {
    try {
      return await api.getLanguageById(languageId);
    } catch (error) {
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

export const loadAllVoice = createAsyncThunk("loadAllVoice", async (_, { rejectWithValue }) => {
  try {
    return await api.getAllVoice();
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const createVoice = createAsyncThunk("createVoice", async (voiceData, { rejectWithValue }) => {
  try {
    return await api.createVoice(voiceData);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const updateVoice = createAsyncThunk(
  "voices/updateVoice",
  async ({ voiceId, voiceData }, { rejectWithValue }) => {
    try {
      return await api.updateVoice(voiceId, voiceData);
    } catch (error) {
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

export const getVoiceById = createAsyncThunk("voices/getVoiceById", async (voiceId, { rejectWithValue }) => {
  try {
    return await api.getVoiceById(voiceId);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const loadAllSynthetic = createAsyncThunk("loadAllSynthetic", async (_, { rejectWithValue }) => {
  try {
    return await api.getAllSyntheticByAuth();
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const addSynthetic = createAsyncThunk(
  "addSynthetic",
  async ({ newSynthetic, provider }, { rejectWithValue }) => {
    try {
      return await api.addSynthetic(newSynthetic, provider);
    } catch (error) {
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

export const editSynthetic = createAsyncThunk("editSynthetic", async ({ newSynthetic }, { rejectWithValue }) => {
  try {
    return await api.editSynthetic(newSynthetic);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const getAllPartners = createAsyncThunk("getAllPartners", async (_, { rejectWithValue }) => {
  try {
    return await api.getAllPartners();
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const sendEmailAboutPartner = createAsyncThunk(
  "sendEmailAboutPartner",
  async (contactInfo, { rejectWithValue }) => {
    try {
      return await api.sendEmailAboutPartner(contactInfo);
    } catch (error) {
      return rejectWithValue(error.message || "An unexpected error occurred");
    }
  }
);

export const loadConfigData = createAsyncThunk("loadConfigData", async (configDataId, { rejectWithValue }) => {
  try {
    return await api.getConfigData(configDataId);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const loadTask = createAsyncThunk("loadTask", async (taskId, { rejectWithValue }) => {
  try {
    return await api.getTaskById(taskId);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const updateTask = createAsyncThunk("updateTask", async (taskId, { rejectWithValue }) => {
  try {
    return await api.updateTaskByGuid(taskId);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const deleteSynthetic = createAsyncThunk("deleteSynthetic", async (synId, { rejectWithValue }) => {
  try {
    return await api.deleteSynthetic(synId);
  } catch (error) {
    return rejectWithValue(error.message || "An unexpected error occurred");
  }
});

export const getSyntheticStyleById = createAsyncThunk(
  "synthetic/getSyntheticStyleById",
  async (syntheticId, { rejectWithValue }) => {
    try {
      return await api.getSyntheticStyleById(syntheticId);
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message || "An unexpected error occurred");
    }
  }
);

export const getSyntheticById = createAsyncThunk(
  "synthetic/getSyntheticById",
  async (syntheticId, { rejectWithValue }) => {
    try {
      return await api.getSyntheticById(syntheticId);
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message || "An unexpected error occurred");
    }
  }
);

export const updateSyntheticStyle = createAsyncThunk(
  "synthetic/updateSyntheticStyle",
  async ({ syntheticId, styleData }, { rejectWithValue }) => {
    try {
      return await api.updateSyntheticStyle(syntheticId, styleData);
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message || "An unexpected error occurred");
    }
  }
);

const userSynthetic = createSlice({
  name: "userSynthetic",
  initialState,
  reducers: {
    closeErrorWindow(state) {
      state.showErrorWindow = false;
      state.errorWindowMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAllAvatar.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.avatars = action.payload;
          state.error = false;
        }
      })
      .addCase(uploadAvatar.pending, (state) => {
        state.isLoadingUploadAvatar = true;
        state.error = false;
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.isLoadingUploadAvatar = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.avatars.push(action.payload);
          state.error = false;
        }
      })
      .addCase(uploadNewPartner.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.error = false;
        }
      })
      .addCase(uploadNewPartner.pending, (state) => {
        state.isLoadingUploadAvatar = true;
        state.error = false;
      })
      .addCase(sendEmailAboutPartner.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.error = false;
        }
      })
      .addCase(sendEmailAboutPartner.pending, (state) => {
        state.isLoadingUploadAvatar = true;
        state.error = false;
      })
      .addCase(sendEmailAboutPartner.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isLoadingUploadAvatar = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(uploadSharedAvatar.pending, (state) => {
        state.isLoadingUploadAvatar = true;
        state.error = false;
      })
      .addCase(uploadSharedAvatar.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isLoadingUploadAvatar = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(uploadSharedAvatar.fulfilled, (state, action) => {
        state.isLoadingUploadAvatar = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.error = false;
        }
      })
      .addCase(getAllPartners.pending, (state) => {
        state.isLoadingUploadAvatar = true;
        state.error = false;
      })
      .addCase(getAllPartners.fulfilled, (state, action) => {
        state.isLoadingUploadAvatar = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.allPartners = action.payload;
          state.error = false;
        }
      })
      .addCase(getAllPartners.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isLoadingUploadAvatar = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(loadAllSharedAvatars.pending, (state) => {
        state.isLoadingUploadAvatar = true;
        state.error = false;
      })
      .addCase(loadAllSharedAvatars.fulfilled, (state, action) => {
        state.isLoadingUploadAvatar = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.sharedAvatars = action.payload;
          state.error = false;
        }
      })
      .addCase(loadAllSharedAvatars.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isLoadingUploadAvatar = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(loadAllLanguage.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.languages = action.payload;
          state.error = false;
        }
      })
      .addCase(createLanguage.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.languages.push(action.payload);
          state.error = false;
        }
      })
      .addCase(updateLanguage.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.languages = action.payload;
          state.error = false;
        }
      })
      .addCase(getLanguageById.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.languages = action.payload;
          state.error = false;
        }
      })
      .addCase(loadAllVoice.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.voices = action.payload;
          state.error = false;
        }
      })
      .addCase(createVoice.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.voices.push(action.payload);
          state.error = false;
        }
      })
      .addCase(updateVoice.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.voices = action.payload;
          state.error = false;
        }
      })
      .addCase(getVoiceById.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.voices = action.payload;
          state.error = false;
        }
      })
      .addCase(loadAllSynthetic.fulfilled, (state, action) => {
        state.isDeletingSyn = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.synthetics = action.payload;
          state.error = false;
        }
      })
      .addCase(loadAllSynthetic.pending, (state) => {
        state.isDeletingSyn = true;
        state.error = false;
      })
      .addCase(loadAllSynthetic.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isDeletingSyn = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(addSynthetic.pending, (state) => {
        state.isLoadingCreateSyn = true;
        state.error = false;
      })
      .addCase(addSynthetic.fulfilled, (state, action) => {
        state.isLoadingCreateSyn = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.synthetics.push(action.payload);
          state.error = false;
        }
      })
      .addCase(addSynthetic.rejected, (state) => {
        state.isLoadingCreateSyn = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })

      .addCase(editSynthetic.pending, (state) => {
        state.isLoadingCreateSyn = true;
        state.error = false;
      })
      .addCase(editSynthetic.fulfilled, (state, action) => {
        state.isLoadingCreateSyn = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.synthetics.push(action.payload);
          state.error = false;
        }
      })
      .addCase(editSynthetic.rejected, (state) => {
        state.isLoadingCreateSyn = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(updateTask.pending, (state) => {
        state.isLoadingUpdateTask = true;
        state.error = false;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.isLoadingUpdateTask = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
          state.showErrorWindow = true;
          state.errorWindowMessage = action.payload.message;
        } else {
          state.error = false;
        }
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isLoadingUpdateTask = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
        state.showErrorWindow = true;
        state.errorWindowMessage = action.payload || "Service error occurred. Ask support or administrator for details";
      })
      .addCase(deleteSynthetic.fulfilled, (state, action) => {
        state.isDeletingSyn = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.error = false;
        }
      })
      .addCase(deleteSynthetic.rejected, (state, action) => {
        state.errorMessage = action.payload || action.error.message;
        state.isDeletingSyn = false;
        state.error = true;
        state.errorExistMessage = "Service error occurred. Ask support or administrator for details";
      })
      .addCase(deleteSynthetic.pending, (state) => {
        state.isDeletingSyn = true;
        state.error = false;
      })
      .addCase(getSyntheticStyleById.pending, (state) => {
        state.isLoadingFetchStyle = true;
        state.error = false;
      })
      .addCase(getSyntheticStyleById.fulfilled, (state, action) => {
        state.isLoadingFetchStyle = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.syntheticStyles[action.meta.arg] = action.payload;
          state.error = false;
        }
      })
      .addCase(getSyntheticStyleById.rejected, (state, action) => {
        state.isLoadingFetchStyle = false;
        state.error = true;
        state.errorExistMessage = action.payload || "An unexpected error occurred";
      })

      .addCase(getSyntheticById.pending, (state) => {
        state.isLoadingFetchStyle = true;
        state.error = false;
      })
      .addCase(getSyntheticById.fulfilled, (state, action) => {
        state.isLoadingFetchStyle = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.synthetic[action.meta.arg] = action.payload;
          state.error = false;
        }
      })
      .addCase(getSyntheticById.rejected, (state, action) => {
        state.isLoadingFetchStyle = false;
        state.error = true;
        state.errorExistMessage = action.payload || "An unexpected error occurred";
      })

      .addCase(updateSyntheticStyle.pending, (state) => {
        state.isLoadingUpdateStyle = true;
        state.error = false;
      })
      .addCase(updateSyntheticStyle.fulfilled, (state, action) => {
        state.isLoadingUpdateStyle = false;
        if (action.payload.message) {
          state.error = true;
          state.errorExistMessage = action.payload.message;
        } else {
          state.syntheticStyles[action.meta.arg.syntheticId] = action.payload;
          state.error = false;
        }
      })
      .addCase(updateSyntheticStyle.rejected, (state, action) => {
        state.isLoadingUpdateStyle = false;
        state.error = true;
        state.errorExistMessage = action.payload || "An unexpected error occurred";
      });
  },
});

export const { closeErrorWindow } = userSynthetic.actions;
export default userSynthetic.reducer;
