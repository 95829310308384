// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";

const periods = [
  { label: "Today", value: "today" },
  { label: "Last 7 days", value: "week" },
  { label: "Last 30 days", value: "month" },
  { label: "Last 365 days", value: "year" },
];

const PeriodSelector = ({ onSelect }) => {
  return (
    <div>
      <label>Period: </label>
      <select onChange={(e) => onSelect(e.target.value)}>
        {periods.map((period) => (
          <option key={period.value} value={period.value}>
            {period.label}
          </option>
        ))}
      </select>
    </div>
  );
};

PeriodSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default PeriodSelector;
