// eslint-disable-next-line no-unused-vars
import React, { useState, useCallback, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import styles from "./blockAlreadyWithUs.module.css";
import { getAllPartners } from "../../../../features/user_synthetic/userSyntheticSlice";
import style from "../partner_with_us/blockPartnerWithUs.module.css";

export default function BlockAlreadyWithUs() {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [partners, setPartners] = useState([]);
  const totalItems = partners.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalItems - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalItems - 1 ? 0 : prevIndex + 1));
  };

  const getVisibleItems = () => {
    const prevItem = (currentIndex === 0 ? totalItems : currentIndex) - 1;
    const nextItem = (currentIndex + 1) % totalItems;

    return {
      left: partners[prevItem],
      center: partners[currentIndex],
      right: partners[nextItem],
    };
  };

  const visibleItems = getVisibleItems();

  const getPartners = useCallback(async () => {
    const allPartners = await dispatch(getAllPartners());
    setPartners(allPartners.payload);
  }, [dispatch]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  return (
    <div>
      <div className={styles.block}>
        <div className={style.blocks}>
          <h2 className={styles.headerAlreadyWithUs}>Already With Us</h2>
          <div className={styles.carouselContainer}>
            {visibleItems.left && (
              <div
                className={`${styles.partnerBlock} ${styles.partnerBlockLeft}`}
                onClick={handlePrev}
                style={{ opacity: 0.5 }}
                key="left"
              >
                <h2 className={styles.partnerName}>{visibleItems.left.name}</h2>
                <img
                  className={styles.imgStyle}
                  src={`data:image/*;base64,${visibleItems.left.image}`}
                  alt={visibleItems.left.name}
                />
                <ul className={styles.descriptionBlock}>
                  <li className={styles.description}>{visibleItems.left.firstDescription}</li>
                  <li className={styles.description}>{visibleItems.left.secondDescription}</li>
                  <li className={styles.description}>{visibleItems.left.thirdDescription}</li>
                </ul>
              </div>
            )}
            {visibleItems.center && (
              <div className={`${styles.partnerBlock} ${styles.partnerBlockCenter}`} key="center">
                <h2 className={styles.partnerName}>{visibleItems.center.name}</h2>
                <img
                  className={styles.imgStyle}
                  src={`data:image/*;base64,${visibleItems.center.image}`}
                  alt={visibleItems.center.name}
                />
                <ul className={styles.descriptionBlock}>
                  <li className={styles.description}>{visibleItems.center.firstDescription}</li>
                  <li className={styles.description}>{visibleItems.center.secondDescription}</li>
                  <li className={styles.description}>{visibleItems.center.thirdDescription}</li>
                </ul>
              </div>
            )}
            {visibleItems.right && (
              <div
                className={`${styles.partnerBlock} ${styles.partnerBlockRight}`}
                onClick={handleNext}
                style={{ opacity: 0.5 }}
                key="right"
              >
                <h2 className={styles.partnerName}>{visibleItems.right.name}</h2>
                <img
                  className={styles.imgStyle}
                  src={`data:image/*;base64,${visibleItems.right.image}`}
                  alt={visibleItems.right.name}
                />
                <ul className={styles.descriptionBlock}>
                  <li className={styles.description}>{visibleItems.right.firstDescription}</li>
                  <li className={styles.description}>{visibleItems.right.secondDescription}</li>
                  <li className={styles.description}>{visibleItems.right.thirdDescription}</li>
                </ul>
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={handlePrev} className={styles.navBtn}>
              <ArrowBackIcon />
            </button>
            <div className={styles.between} />
            <button onClick={handleNext} className={styles.navBtn}>
              <ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
