import React from "react";
import styles from "./blockPerfectFor.module.css";

function BlockPerfectFor() {
  return (
    <div>
      <div className={styles.blockPerfectFor}>
        <h2 className={styles.perfectFor}>Perfect For</h2>
        <div className={styles.listWithContent}>
          <ul className={styles.list}>
            <li className={styles.lineBlock}>
              <div className={styles.textAndLine1}>
                <div className={styles.textContent}>Landing page optimization</div>
                <hr className={styles.line} />
              </div>
            </li>
            <li className={styles.lineBlock}>
              <div className={styles.textAndLine2}>
                <hr className={styles.line} />
                <div className={styles.textContent}>Website engagement enhancement</div>
              </div>
            </li>
            <li className={styles.lineBlock}>
              <div className={styles.textAndLine1}>
                <div className={styles.textContent}>A/B testing and conversion rate optimization</div>
                <hr className={styles.line} />
              </div>
            </li>
            <li className={styles.lineBlock}>
              <div className={styles.textAndLine2}>
                <hr className={styles.line} />
                <div className={styles.textContent}>Client retention and satisfaction improvement</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BlockPerfectFor;
