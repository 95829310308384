import { axiosDefault } from "../../features/auth/service/axios.config";

export async function addNewsletter(newsletter) {
  const res = await axiosDefault.post("/api/newsletters/add", JSON.stringify(newsletter));
  if (res.status !== 201) {
    throw res.data;
  }
  return res.data;
}

export async function getAllNewsletter() {
  const res = await axiosDefault.get("/api/newsletters");
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}
