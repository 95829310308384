// eslint-disable-next-line no-unused-vars
import React from "react";
import style from "./partnerPage.module.css";
import BlockPartnerWithUs from "./partner_page_components/partner_with_us/BlockPartnerWithUs";
import BlockHowItWorks from "./partner_page_components/how_it_works/BlockHowItWorks";
import BlockPerfectFor from "./partner_page_components/perfect_for/BlockPerfectFor";
import BlockAlreadyWithUs from "./partner_page_components/already_with_us/BlockAlreadyWithUs";
import BlockContactUs from "./partner_page_components/contact_us/BlockContactUs";

function PartnersPage() {
  return (
    <div className={style.mainContainer}>
      <BlockPartnerWithUs />
      <BlockHowItWorks />
      <BlockPerfectFor />
      <BlockAlreadyWithUs />
      <BlockContactUs />
    </div>
  );
}

export default PartnersPage;
