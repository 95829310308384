// eslint-disable-next-line no-unused-vars
import React from "react";
import styles from "./confirmDeleteSynWindow.module.css";
import Button from "../../button/Button";
import buttonStyle from "../../button/button.module.css";

function ConfirmDeleteSynWindow({ handleConfirm, handleCancel }) {
  return (
    <div>
      <button type="button" className={styles.confirmWindowBody} onClick={handleCancel}>
        {" "}
      </button>
      <div className={styles.confirmWindow}>
        <h2 className={styles.title}>Confirmation window</h2>
        <div className={styles.textOfAction}>
          <h1>
            Do you really want to <b>delete</b> this synthetic?
          </h1>
        </div>
        <div className={styles.btnBoxByConfirm}>
          <Button
            buttonName="Yes"
            buttonType="button"
            onClick={handleConfirm}
            className={`${buttonStyle.buttonGlobal} `}
          />

          <Button
            buttonName="Cancel"
            buttonType="button"
            onClick={handleCancel}
            className={`${buttonStyle.buttonCancel} `}
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeleteSynWindow;
