import React from "react";
import PropTypes from "prop-types";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import styles from "./nameSynthetic.module.css";

function NameSynthetic({ name, nameCheck, checkDoneName }) {
  return (
    <div className={styles.nameBlock}>
      {checkDoneName != null &&
        (checkDoneName ? <DoneIcon style={{ color: "green" }} /> : <WarningAmberIcon style={{ color: "#EC4A0A" }} />)}
      <span className={styles.nameText}>Name of synthetic:</span>
      <input
        className={styles.nameInput}
        type="text"
        name="name"
        value={name}
        id="nameInputSynthetic"
        onChange={(e) => {
          nameCheck(e);
        }}
      />
    </div>
  );
}

NameSynthetic.propTypes = {};

export default NameSynthetic;
