// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./formComponent.module.css";
import buttonStyle from "../../button/button.module.css";
import Button from "../../button/Button";
import { sendEmailAboutPartner } from "../../../features/user_synthetic/api";

const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
    setNameError(!/^[a-zA-Z ]+$/.test(value));
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value));
  };

  const checkFullCorrect = () => {
    return !nameError && !emailError && name !== "" && email !== "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkFullCorrect()) {
      const contactInfo = { name, email };
      setIsSubmitting(true);
      setSuccessMessage("");
      try {
        await sendEmailAboutPartner(contactInfo);
        setName("");
        setEmail("");
        setShowError(false);
        setSuccessMessage("Your message has been sent successfully!");
      } catch (error) {
        console.error("Error sending email:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <form className={styles.mainForm} onSubmit={handleSubmit}>
      <div className={styles.containerInputs}>
        <div className={styles.formGroup}>
          <label className={styles.labelText} htmlFor="name">
            Your name
          </label>
          <input
            className={styles.inputText}
            type="text"
            id="name"
            name="name"
            placeholder="-"
            value={name}
            onChange={handleNameChange}
            required
          />
          {showError && nameError && (
            <p className={styles.lineCheckValidPass}>
              <span className={`${styles.iconCheckPass} ${styles.cross}`} />
              <span className={styles.errorMessage}>Wrong name format</span>
            </p>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.labelText} htmlFor="email">
            E-mail for communication
          </label>
          <input
            className={styles.inputText}
            type="email"
            id="email"
            name="email"
            placeholder="-"
            value={email}
            onChange={handleEmailChange}
            required
          />
          {showError && emailError && (
            <p className={styles.lineCheckValidPass}>
              <span className={`${styles.iconCheckPass} ${styles.cross}`} />
              <span className={styles.errorMessage}>Wrong email format</span>
            </p>
          )}
        </div>
        {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
      </div>
      <div className={styles.whitePart}>
        <div>
          <Button
            buttonName="Send"
            buttonType="submit"
            onClick={() => {}}
            className={`${buttonStyle.buttonSave} ${buttonStyle.buttonSave} ${checkFullCorrect() ? "" : buttonStyle.buttonDisabled}`}
            disabled={!checkFullCorrect() || isSubmitting}
            icon={<ArrowForwardIcon />}
          />
        </div>
      </div>
    </form>
  );
};

export default ContactUsForm;
