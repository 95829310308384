// eslint-disable-next-line no-unused-vars
import React from "react";
import style from "./blockHowItWorks.module.css";

function BlockHowItWorks() {
  return (
    <div>
      <div className={style.block}>
        <div className={style.blocks}>
          <h2 className={style.howItWorks}>How It Works</h2>
          <div className={style.rangedBlockes}>
            <div className={style.sign}>
              <p className={style.stepName}>Sign up:</p>
              <div className={style.stepText}>Join our partner program for free.</div>
            </div>
            <div className={style.integrate}>
              <p className={style.stepName}>Integrate:</p>
              <div className={style.stepText}>
                Easily incorporate our solution into your clients' digital marketing strategies.
              </div>
            </div>
            <div className={style.earn}>
              <p className={style.stepName}>Earn:</p>
              <div className={style.stepText}>
                Get a generous share of the recurring revenue from each client subscription.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockHowItWorks;
