// eslint-disable-next-line no-unused-vars
import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./commonErrorWindow.module.css";
import Button from "../../button/Button";
import buttonStyle from "../../button/button.module.css";

function CommonErrorWindow({ open, onClose, text, redirectToLogin }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!open) return null;

  const defaultErrorMessage =
    "Sorry, something went wrong. We apologize for the inconvenience. Please contact us for assistance.";

  const onButtonClick = () => {
    if (redirectToLogin) {
      navigate("/login");
    } else {
      navigate("/contactUs");
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={styles.confirmWindow}>
      <div className={styles.calcByConfirmBox}>
        <div className={styles.windowHeader}>
          <div className={styles.windowName}>
            <WarningAmberIcon style={{ color: "#EC4A0A" }} />
            <h3>{redirectToLogin ? "Session Expired" : "Error"}</h3>
          </div>
          <button type="button" className={styles.closeButton} onClick={onClose} aria-label="Close">
            <CloseIcon />
          </button>
        </div>
        <div>{text || defaultErrorMessage}</div>
        <div className={styles.btnBoxByConfirm}>
          <Button
            buttonName={redirectToLogin ? "Go to Login" : t("contactBtn")}
            buttonType="button"
            onClick={onButtonClick}
            className={buttonStyle.buttonApply}
          />
        </div>
      </div>
    </div>
  );
}

CommonErrorWindow.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  text: PropTypes.string,
  redirectToLogin: PropTypes.bool,
};

CommonErrorWindow.defaultProps = {
  text: "",
  redirectToLogin: false,
  onClose: true,
};

export default CommonErrorWindow;
