// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/selectors.js";
import styles from "./confirmCreateSynWindow.module.css";
import Button from "../../button/Button";
import buttonStyles from "../../button/button.module.css";

function ConfirmCreateSynWindow({
  text,
  newSynthetic,
  setConfirmWindowIsActive,
  handleCancel,
  handleCreate,
  getAvatarById,
  handleCreateConfirmWindow,
}) {
  const user = useSelector(selectUser);

  const AVERAGE_READING_SPEED_WORDS_PER_MINUTE = 115;
  const SECONDS_PER_MINUTE = 60;
  const SECONDS_IN_COIN = 15;

  function calculateCoinFromAudioDuration() {
    const wordCount = text.trim().split(/\s+/).length;
    const durationInSeconds = wordCount / (AVERAGE_READING_SPEED_WORDS_PER_MINUTE / SECONDS_PER_MINUTE);
    return Math.max(1, Math.ceil(durationInSeconds / SECONDS_IN_COIN));
  }

  const isBalanceSufficient = calculateCoinFromAudioDuration() <= user.balance;

  return (
    <div>
      <button
        type="button"
        className={styles.confirmWindowBody}
        onClick={handleCreateConfirmWindow}
        aria-label="Open confirmation window"
      />
      <div className={styles.confirmWindow}>
        <div className={styles.avatarBoxByConfirm}>
          <img className={styles.avaByConfirm} src={`data:image/*;base64,${getAvatarById()}`} alt="confirmAva" />
          <p>{newSynthetic.name}</p>
        </div>
        <div className={styles.calcByConfirmBox}>
          <div>
            <h3>Calculation coins</h3>
            <p className={`${styles.coinsCalcText} ${!isBalanceSufficient && styles.noAccess}`}>
              {calculateCoinFromAudioDuration()} Coin *
            </p>
          </div>
          <div className={styles.btnBoxByConfirm}>
            {isBalanceSufficient && (
              <Button
                buttonName="Apply"
                onClick={handleCreate}
                className={buttonStyles.buttonApply}
                disabled={!isBalanceSufficient}
              />
            )}
            <Button
              buttonName="Edit"
              onClick={() => setConfirmWindowIsActive(false)}
              className={buttonStyles.buttonEdit}
            />
            <Button buttonName="Cancel" onClick={handleCancel} className={buttonStyles.buttonCancel} />
          </div>
          <div>
            {isBalanceSufficient ? (
              <p>* Approximate number of coins, the exact number will be written off after successful generation.</p>
            ) : (
              <p>* The cost of creating a synthetic exceeds your current balance.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmCreateSynWindow.propTypes = {
  text: PropTypes.string.isRequired,
  newSynthetic: PropTypes.shape({
    text: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  setConfirmWindowIsActive: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  getAvatarById: PropTypes.func.isRequired,
  handleCreateConfirmWindow: PropTypes.func.isRequired,
};

export default ConfirmCreateSynWindow;
